import { getPublicCp, getJiHuaCp, getCpsDetail } from '@/api/public/lottery'
import { ballsComposition, formatLhName } from '@/utils/lhFormater'
import moment from 'moment'

function _createStruct() {
  return {
    lotteryList: [], // [{ key: info }]
    lotteryMap: {}, // { key: info }
    lotteryNameMapping: {}, // { key: name }
    lotteryTypeItems: {}, // { type: [{ key: name }] }
    everStore: false
  }
}

// 過濾後端資料格式
const setLot = (apiData) => {
  return {
    name: formatLhName(apiData.prePeriod.code),
    code: apiData.prePeriod.code,
    period: apiData.prePeriod.period,
    // 下期開獎時間使用 utc 時間
    drawTime:
      apiData.nextPeriod &&
      moment(apiData.nextPeriod.drawTime)
        .utc()
        .valueOf(),
    // 組合彩球資訊
    balls: ballsComposition({
      number: apiData.prePeriod.balls,
      seBo: apiData?.prePeriod?.ruleDetail?.seBo || [],
      wuXing: apiData.prePeriod.ruleDetail.wuXing,
      shengXiao: apiData.prePeriod.ruleDetail.shengXiao
    }),
    tableInfo: {
      zongHe: apiData.prePeriod.ruleDetail.zongHe[0],
      zongHeDanShuang: apiData.prePeriod.ruleDetail.zongHeDanShuang[0],
      zongHeDaXiao: apiData.prePeriod.ruleDetail.zongHeDaXiao[0],
      zongHeYanSe: apiData.prePeriod.ruleDetail.chiSeBo,
      teMaDanShuang: apiData.prePeriod.ruleDetail.hmDanShuang[6],
      teMaDaXiao: apiData.prePeriod.ruleDetail.hmDaXiao[6]
    }
  }
}

export const state = () => ({
  all: _createStruct(),
  shaHao: _createStruct(), // 殺號計畫
  zhuanJia: _createStruct(), // 專家推薦
  liangMian: _createStruct(), // 兩面推薦
  gouCai: _createStruct(), // 購彩計畫
  jingCai: _createStruct(), // 競猜,
  video: {
    active: false,
    lotteryCode: '',
    name: ''
  },
  description: {
    active: false,
    lotteryCode: '',
    name: '',
    descriptionType: ''
  },
  activeNumList: [],
  activeLiangMianList: [],
  cpsDetail: [],
  pk10Detail: [],
  // 彩種清單
  cpsList: [],
  currentCanvasTukuURL: '',
  directEnterImageItem: false // 直接點擊進入 pk10 圖庫頁面
})

export const mutations = {
  SET_CPS_LIST(state, payload) {
    state.cpsList = payload
  },
  SET_CPS_DETAIL(state, payload) {
    // 全部六合彩彩種
    const lhcDetailKeys = Object.keys(payload).filter(
      (lotteryCode) => payload[lotteryCode].cpType === 'sixhc'
    )
    const pk10DetailKeys = Object.keys(payload).filter(
      (lotteryCode) => payload[lotteryCode].cpType === 'pk10'
    )
    const detailMap = {}
    lhcDetailKeys.forEach((lotteryCode) => {
      detailMap[lotteryCode] = payload[lotteryCode]
    })

    const pk10DetailMap = {}
    pk10DetailKeys.forEach((lotteryCode) => {
      pk10DetailMap[lotteryCode] = payload[lotteryCode]
    })

    state.cpsDetail = detailMap
    state.pk10Detail = pk10DetailMap
  },
  SET_PUBLIC_CP(state, { result, type, payload }) {
    const {
      list: lotteryList,
      map: lotteryMap,
      lotteryNameMapping,
      lotteryTypeItems
    } = result
    const everStore = true
    const lhcDetailKeys = Object.keys(payload).filter(
      (lotteryCode) => payload[lotteryCode].cpType === 'sixhc'
    )
    const detailMap = {}
    lhcDetailKeys.forEach((lotteryCode) => {
      detailMap[lotteryCode] = payload[lotteryCode]
    })
    state.cpsDetail = detailMap
    if (!state[type]) {
      console.warn(
        'SET_PUBLIC_CP: 對應彩票列表類型失敗! vuex state沒有更新',
        type
      )
      return
    }

    Object.assign(state[type], {
      everStore,
      lotteryList,
      lotteryMap,
      lotteryNameMapping,
      lotteryTypeItems
    })
  },
  OPEN_VIDEO(state, paload) {
    state.video.active = true
    state.video.lotteryCode = paload.lotteryCode
    state.video.name = paload.name
  },
  CLOSE_VIDEO(state) {
    state.video.active = false
    state.video.lotteryCode = ''
    state.video.name = ''
  },
  SET_NUMBER_LIST(state, payload) {
    state.activeLiangMianList = []
    state.activeNumList = JSON.parse(JSON.stringify(payload))
  },
  SET_LIANGMIAN_LIST(state, payload) {
    state.activeNumList = []
    state.activeLiangMianList = JSON.parse(JSON.stringify(payload))
  },
  RESET_ACTIVE_LIST(state) {
    state.activeLiangMianList = []
    state.activeNumList = []
  },
  OPEN_DESCRIPTION(state, payload) {
    state.description.active = true
    state.description.lotteryCode = payload.lotteryCode
    state.description.name = payload.name
    state.description.descriptionType =
      payload.descriptionType === undefined
        ? 'lotteryItem'
        : payload.descriptionType
  },
  CLOSE_DESCRIPTION(state, payload) {
    state.description.active = false
    state.description.lotteryCode = ''
    state.description.name = ''
  },
  SET_CANVAS_NEWS_URL(state, payload) {
    state.currentCanvasTukuURL = payload
  },
  SET_DIRECT_ENTER_IMAGE(state, payload) {
    state.directEnterImageItem = payload
  }
}

export const actions = {
  // 彩種對姓名
  async getLotteryNameMapping({ commit, state }, type = 'all') {
    if (state[type].everStore) return [state[type].lotteryNameMapping, null]

    const { error, lotteryNameMapping } = await _requestAndCommit(commit, type)
    if (error) return [null, error]

    return [lotteryNameMapping, null]
  },

  // 彩種對資料
  async getLotteryMap({ commit, state }, type = 'all') {
    if (state[type].everStore) return [state[type].lotteryMap, null]

    const { error, map } = await _requestAndCommit(commit, type)
    if (error) return [null, error]

    return [map, null]
  },

  // 彩種資料列表
  async getLotteryList({ commit, state }, type = 'all') {
    if (state[type].everStore) return [state[type].lotteryList, null]

    const { error, list } = await _requestAndCommit(commit, type)
    if (error) return [null, error]

    return [list, null]
  },

  async getLotteryTypeItems({ commit, state }, type = 'all') {
    if (state[type].everStore) return [state[type].lotteryTypeItems, null]

    const { error, lotteryTypeItems } = await _requestAndCommit(commit, type)
    if (error) return [null, error]

    return [lotteryTypeItems, null]
  },
  async getCpsDetail({ state, commit, rootState, dispatch }, cpCode) {
    // cpCode 不傳值預設使用熱門彩種清單
    const [data, error] = await getCpsDetail({
      cpCode: cpCode || rootState.site.wapHotLotteryTab.join(',')
    })
    if (error) {
      console.error(`[error]:`, error)
      setTimeout(() => {
        dispatch('getCpsDetail', cpCode)
      }, 3000)
      return false
    }

    // for 個別彩種更新倒數開獎
    if (cpCode) {
      const lotteryIndex = state.cpsList.findIndex((lot) => lot.code === cpCode)
      const oldLottery = state.cpsList[lotteryIndex]
      const currentLottery = data[cpCode].prePeriod
      const nextDrawTime = data[cpCode].nextPeriod.drawTime
      // 當前期數跟舊的一樣 或者 下期開獎時間小於當前時間 的話重新送 api
      if (
        (oldLottery && oldLottery.period) ===
          (currentLottery && currentLottery.period) ||
        moment(nextDrawTime)
          .utc()
          .valueOf() <
          moment()
            .utc()
            .valueOf()
      ) {
        setTimeout(() => {
          dispatch('getCpsDetail', cpCode)
        }, 3000)
        return true
      }
      const returnList = [...state.cpsList]
      returnList.splice(lotteryIndex, 1, setLot(data[cpCode]))
      commit('SET_CPS_LIST', returnList)
      return true
    }
    // 按照熱門彩種排序
    // wapHotLotteryTab 是只給圖庫 tab 使用
    commit(
      'SET_CPS_LIST',
      rootState.site.wapHotLotteryTab.map((lotCode) => setLot(data[lotCode]))
    )
    return true
  },
  async getPublicCp({ commit, dispatch }) {
    const [data, error] = await getPublicCp()

    if (error) {
      console.error(`[error]:`, error)
      setTimeout(() => {
        dispatch('getPublicCp')
      }, 3000)
    } else {
      commit('SET_CPS_DETAIL', { ...data })
      return true
    }
  },
  async setCanvasNewsURL({ commit }, payload) {
    commit('SET_CANVAS_NEWS_URL', payload)
  }
}

async function _requestAndCommit(commit, type) {
  let data, error
  switch (type) {
    case 'shaHao': // 殺號計畫
    case 'zhuanJia': // 專家推薦
    case 'liangMian': // 兩面推薦
    case 'gouCai': // 購彩計畫
    case 'jingCai': // 競猜
      [data, error] = await getJiHuaCp(type)
      break

    case 'all': // 所有支援的彩種
    default:
      if (type !== 'all') {
        console.warn(
          "lottery dispatch: lotteryListType 對應失敗! 將使用 'all'.",
          type
        )
      }
      [data, error] = await getPublicCp()
      break
  }
  if (error) return { error }

  const result = __transformLotteryData(data, type)
  const payload = { ...data }
  commit('SET_PUBLIC_CP', { result, type, payload })
  return result
}

function __transformLotteryData(map, type) {
  const lotteryNameMapping = {}
  let lotteryTypeItems = {}
  // 對資料先進行初步排序
  const sortedArray = Object.keys(map)
    .map((key) => map[key])
    .sort((a, b) => a.sort - b.sort)
  const list = sortedArray.reduce((list, lotteryItem) => {
    let lotteryInfoItem = lotteryItem
    if (type !== 'all') {
      lotteryInfoItem = lotteryInfoItem.baseCp
    }

    // 彩種類型轉換: 前端用的跟後端用的不一樣
    const cpType = lotteryInfoItem.cpType
    // 彩種代號轉換: 匹配從 public/cp 拿到的資料跟 jihua/cp 拿到的資料
    const cpCode = lotteryInfoItem.code
    const pruneLottery = (({ name: label, code: lotteryCode }) => ({
      label,
      lotteryCode
    }))(lotteryInfoItem)
    // 將彩種類型的順序記錄下來，改為物件結構
    lotteryTypeItems[cpType] = lotteryTypeItems[cpType] || {
      list: undefined,
      sort: undefined
    }
    lotteryTypeItems[cpType].list = lotteryTypeItems[cpType].list || []
    lotteryTypeItems[cpType].list.push(pruneLottery)
    // 因後端給的順序資料為三碼，頭一碼對彩種類型排序
    lotteryTypeItems[cpType].sort =
      lotteryTypeItems[cpType].sort ||
      parseInt(`${lotteryItem.sort}`.slice(0, 1), 10)

    // 彩種名字對應
    lotteryNameMapping[cpCode] = lotteryInfoItem.name

    // 物件格式轉換
    list.push({ lotteryCode: cpCode, data: lotteryItem })
    return list
  }, [])

  // 重新定義lotteryType
  lotteryTypeItems = Object.keys(lotteryTypeItems).reduce(
    (map, lotteryType) => {
      const obj = lotteryTypeItems[lotteryType]
      switch (lotteryType) {
        // 11選5
        case 'syx5':
          map['11x5'] = obj
          break
        // 六合彩
        case 'sixhc':
          map['hk6'] = obj
          break
        // 其他彩種
        case 'normal':
          map['others'] = obj
          break

        case 'k3':
        case 'kl8':
        case 'kl10':
        case 'pk10':
        case 'ssc':
        case 'xy28':
          map[lotteryType] = obj
          break
      }
      return map
    },
    {}
  )

  return { list, map, lotteryNameMapping, lotteryTypeItems }
}
